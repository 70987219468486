import React from 'react';
import styles from './header.module.scss';
import { userContext } from '../auth/auth.context';
import { Hamburger } from '@components/hamburger/hamburger';
import { useTranslation } from 'next-i18next';
import AsyncImage from '@components/async-image/async-image';

interface headerProps {
	headerProps: Record<string, unknown>;
}

const Header = ({ headerProps }: headerProps) => {
	const auth = React.useContext(userContext);
	const { i18n } = useTranslation();
	const lang = i18n.language;

	function onAuthClick(event) {
		switch (event?.type) {
			case 'login': {
				auth.openLoginModal();
				break;
			}

			case 'logout': {
				auth.logout();
				break;
			}

			default:
				break;
		}
	}

	return (
		<header className={`${styles.header} flexbox corners`} style={headerProps?.styles}>
			<a className={`${styles['header-logo-link']} flexbox`} href="https://www.anghami.com">
				<div className={styles.logo}>
					<AsyncImage
						imgSrc={
							headerProps?.logo === 'white'
								? lang === 'ar'
									? 'logos/NEW_LOGO_HORIZONTAL_WHITE_AR.png'
									: 'logos/New_Logo_Horizontal_White.png'
								: lang === 'ar'
									? 'logos/NEW_LOGO_HORIZONTAL_AR.png'
									: 'new-anghami-logos/header-logo-fullblack.png'
						}
						alt="Anghami"
						width={150}
						height={50}
					/>
				</div>
			</a>
			<Hamburger
				isWhiteLogo={Boolean(headerProps?.logo === 'white')}
				user={auth?.user}
				onAuthClick={(evt) => {
					onAuthClick(evt);
				}}
			/>
		</header>
	);
};

export default Header;
