import React from 'react';

export interface Auth {
	user: any;
	setUser: (user: any) => void;
	loggedIn: boolean;
	setLoggedIn: (user: any) => void;
	setLoginModalState: (state: boolean) => void;
	loginModalState: boolean;
	openLoginModal: () => void;
	logout: () => void;
	showAccountRecoverModal: (state: boolean) => void;
	accountRecoverModalState: boolean;
}

const userContext = React.createContext<Auth>({
	user: null,
	setUser: () => null,
	loggedIn: false,
	setLoggedIn: () => null,
	setLoginModalState: (state) => state,
	loginModalState: false,
	openLoginModal: () => null,
	logout: () => null,
	showAccountRecoverModal: (state) => state,
	accountRecoverModalState: false
});

export { userContext };
