import React from 'react';
import Image, { ImageLoaderProps } from 'next/image';
import styles from './async-image.module.scss';
import { environment } from '@environment';
import { getCoverArtImage } from '@helpers/operators-helper';

type ImageProps = {
	imgSrc?: string;
	coverArtId?: string;
	width?: number;
	height?: number;
	objectFitStyle?: 'contain' | 'cover';
	imageClass?: string;
	isFullWidth?: boolean;
	alt?: string;
};

export const imageLoader = ({ src }: ImageLoaderProps) => {
	if (src.startsWith('http')) {
		return src;
	}

	return `${environment.web_cdn_url}web/assets/img/${src}`;
};

const AsyncImage = ({
	imgSrc,
	coverArtId,
	width,
	height,
	imageClass,
	isFullWidth,
	alt,
	objectFitStyle
}: ImageProps) => {
	return (
		<Image
			className={`${styles.async_image} ${styles[imageClass]}`}
			loader={imageLoader}
			src={imgSrc || getCoverArtImage(coverArtId)}
			alt={alt || ''}
			width={width}
			height={height || width}
			layout={isFullWidth ? 'responsive' : 'fixed'}
			objectFit={objectFitStyle || 'contain'}
			loading="lazy"
		/>
	);
};

export default AsyncImage;
