import React, { useState } from 'react';
import styles from './hamburger.module.scss';
import { useTranslation } from 'next-i18next';
import { environment } from '@environment';
import { handleProfilePictureError, isMobile } from '@helpers/helper';

interface HamburgerProps {
	isWhiteLogo: boolean;
	user: any;
	onAuthClick: ({ type }) => void;
}

export const Hamburger = ({ isWhiteLogo, user, onAuthClick }: HamburgerProps) => {
	const { t, i18n } = useTranslation();
	const lang = i18n.language;
	const [toggleValue, toggleMenu] = useState(false);

	function onToggleMenu(toggleVal: boolean) {
		const hamburgerNav = document.getElementById('hamburger-nav-id');
		toggleMenu(toggleVal);
		if (hamburgerNav && toggleVal) {
			hamburgerNav.setAttribute('style', 'visibilty: unset');
		}
	}

	function redirectToProfilePage(id) {
		window.location.href = `https://www.anghami.com/profile/${id}`;
	}

	return (
		<div className={styles.banner}>
			<button
				className={`${styles.hamburger} ${styles['hamburger--collapse']} ${
					toggleValue ? styles['is-active'] : ''
				}`}
				type="button"
				aria-label="Menu"
				onClick={() => {
					onToggleMenu(!toggleValue);
				}}
			>
				<span className={styles['hamburger-box']}>
					<span
						className={`${styles['hamburger-inner']}
							${isWhiteLogo ? styles['hamburger-inner-white'] : ''}`}
					/>
				</span>
			</button>
			<nav className={`${toggleValue ? styles['is-active'] : ''} flexbox`}>
				{toggleValue ? (
					<div className={styles['mobile-logo']}>
						<img
							alt="Anghami"
							src={`${environment.web_cdn_url}web/assets/img/${
								lang === 'ar'
									? 'logos/Logo_Horizontal_AR_colored_DarkMode.png'
									: 'new-anghami-logos/header-logo-colored.png'
							}`}
						/>
					</div>
				) : null}
				<a href="https://www.anghami.com/help">{t('Help')}</a>
				<a href="https://www.anghami.com/plus">{t('Anghami Plus')}</a>
				<span className={styles.sep}>|</span>
				{isMobile() ? (
					<a href="https://www.anghami.com/openapp">{t('Open app')}</a>
				) : (
					<a href="https://www.anghami.com/home" className={styles.login}>
						{t('Go to web player')}
					</a>
				)}

				{user ? (
					<a
						className={styles.login}
						onClick={() => {
							onAuthClick({
								type: 'logout'
							});
						}}
					>
						{t('Logout')}
					</a>
				) : (
					<a
						className={styles.login}
						id="header_login_button"
						onClick={() => {
							onAuthClick({
								type: 'login'
							});
						}}
					>
						{t('Login')}
					</a>
				)}

				{user && (
					<div className={`flexbox colls centered ${styles.spacing}`}>
						<img
							id="user_profile_img"
							className={styles['user-image']}
							src={user.image}
							alt={user.name}
							onError={handleProfilePictureError}
							onClick={() => {
								redirectToProfilePage(user.id);
							}}
						/>
						{user.plantype === 3 && (
							<div className={`${styles['plan-badge']} ${styles['plus-badge']}`} />
						)}
						{user.plantype === 7 && (
							<div className={`${styles['plan-badge']} ${styles['gold-badge']}`} />
						)}
					</div>
				)}
			</nav>
		</div>
	);
};

export default Hamburger;
